import _toPropertyKey from "./toPropertyKey.js";
var exports = {};
var toPropertyKey = _toPropertyKey;
function _defineProperty(e, r, t) {
  return (r = toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
exports = _defineProperty, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;